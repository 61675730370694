<template>
    <div class="container">
        <div class="toolbar">
            <el-row :gutter="100">
                <el-col :span="10" style="display: flex;flex-direction: column">
                    <p>选择代理</p>
                    <el-select v-model="provinceValue" placeholder="请选择省账户" @change="onProvinceChange" v-if="!Boolean(loginData.category1code)">
                        <el-option
                                v-for="item in provinceOptions"
                                :key="item.category1code"
                                :label="item.categoryname"
                                :value="item.category1code">
                        </el-option>
                    </el-select>
                    <el-select v-model="cityValue" placeholder="请选择地级市账户"  @change="onCityChange" v-if="!Boolean(loginData.category2code)">
                        <el-option
                                v-for="item in cityOptions"
                                :key="item.category2code"
                                :label="item.categoryname"
                                :value="item.category2code">
                        </el-option>
                    </el-select>
                    <el-select v-model="agentValue" placeholder="请选择代理人账户" @change="onAgentChange" v-if="!Boolean(loginData.category3code)">
                        <el-option
                                v-for="item in agentOptions"
                                :key="item.category3code"
                                :label="item.categoryname"
                                :value="item.category3code">
                        </el-option>
                    </el-select>
                    <el-select v-model="hospitalValue" placeholder="请选择医院" @change="onHospitalChange" v-if="!Boolean(loginData.category4code)">
                        <el-option
                                v-for="item in hospitalOptions"
                                :key="item.category4code"
                                :label="item.categoryname"
                                :value="item.category4code">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="12" style="display: flex;flex-direction: column">
                    <p>选择时间</p>
                    <el-date-picker
                            @change="onTimeChange"
                            v-model="times"
                            type="daterange"
                            :picker-options="pickerOptions"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            align="right">
                    </el-date-picker>
                </el-col>
            </el-row>
            <el-row :gutter="100">
                <el-col :span="10" style="display: flex;flex-direction: column">
                    <p>操作</p>
                    <div style="display: flex;flex-direction: row">
                        <el-button type="warning" plain style="width: 100px" @click="reset">重置</el-button>
                        <el-button type="primary" plain style="width: 100px" @click="searchEarningsList">搜索</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-divider></el-divider>
        <div style="padding: 20px">
            <el-table
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        type="index"
                        label="#"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="orderamount"
                        label="充值金额">
                </el-table-column>
                <el-table-column
                        prop="paynums"
                        label="可答题次数">
                </el-table-column>
                <el-table-column
                        prop="nums"
                        label="购买次数">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import api from "../../api";

    export default {
        name: "OperateDashboard",
        data(){
            return {
                provinceValue: "",
                provinceOptions: [],
                cityValue: "",
                cityOptions: [],
                agentValue: "",
                agentOptions: [],
                hospitalValue: "",
                hospitalOptions: [],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                times:'',
                loginData: null,
                startDate: '',
                endDate: '',
                tableData: [],
            }
        },
        created() {
            this.loginData = JSON.parse(localStorage.getItem("logindata"));
            switch (this.loginData.userlevel) {
                case "0":
                    api.getUserLevel1CategoryList().then((res) =>{
                        if (res.data.success === '1'){
                            this.provinceOptions = res.data.data;
                        }
                    }).catch(() =>{});
                    break;
                case "1":
                    this.provinceValue = this.loginData.category1code;
                    api.getUserLevel2CategoryList({
                        category1code: this.loginData.category1code
                    }).then((res) =>{
                        if (res.data.success === '1'){
                            this.cityOptions = res.data.data;
                        }
                    }).catch(() =>{});
                    break;
                case "2":
                    this.provinceValue = this.loginData.category1code;
                    this.cityValue = this.loginData.category2code;
                    api.getUserLevel3CategoryList({
                        category1code: this.loginData.category1code,
                        category2code: this.loginData.category2code
                    }).then((res) =>{
                        if (res.data.success === '1'){
                            this.agentOptions = res.data.data;
                        }
                    }).catch(() =>{});
                    break;
                case "3":
                    this.provinceValue = this.loginData.category1code;
                    this.cityValue = this.loginData.category2code;
                    this.agentValue = this.loginData.category3code;
                    api.getUserLevel4CategoryList({
                        category1code: this.loginData.category1code,
                        category2code: this.loginData.category2code,
                        category3code: this.loginData.category3code
                    }).then((res) =>{
                        if (res.data.success === '1'){
                            this.hospitalOptions = res.data.data;
                        }
                    }).catch(() =>{});
                    break;
            }
        },
        methods: {
            onProvinceChange(val){
                let item = getItemInArray(val,this.provinceOptions,1);
                if (item === null) return;
                api.getUserLevel2CategoryList({
                    category1code: item ? item.category1code : this.loginData.category1code
                }).then((res) =>{
                    if (res.data.success === '1'){
                        this.cityOptions = res.data.data;
                    }
                }).catch(() =>{});
            },
            onCityChange(val){
                let item = getItemInArray(val,this.cityOptions,2);
                if (item === null) return;
                api.getUserLevel3CategoryList({
                    category1code: item ? item.category1code : this.loginData.category1code,
                    category2code: item ? item.category2code : this.loginData.category2code
                }).then((res) =>{
                    if (res.data.success === '1'){
                        this.agentOptions = res.data.data;
                    }
                }).catch(() =>{});
            },
            onAgentChange(val){
                let item = getItemInArray(val,this.agentOptions,3);
                if (item === null) return;
                api.getUserLevel4CategoryList({
                    category1code: item ? item.category1code : this.loginData.category1code,
                    category2code: item ? item.category2code : this.loginData.category2code,
                    category3code: item ? item.category3code : this.loginData.category3code,
                }).then((res) =>{
                    if (res.data.success === '1'){
                        this.hospitalOptions = res.data.data;
                    }
                }).catch(() =>{});
            },
            onHospitalChange(val){
                console.log(val);
                console.log(this.provinceValue + this.cityValue + this.agentValue + this.hospitalValue)
                this.searchEarningsList();
            },
            searchEarningsList(){
                api.getUserEarningsList({
                    category1code: this.provinceValue,
                    category2code: this.cityValue,
                    category3code: this.agentValue,
                    category4code: this.hospitalValue,
                    starttime: this.startDate,
                    endtime: this.endDate,
                }).then((res) =>{
                    this.tableData = res.data.data.list;
                }).catch(()=>{});
            },
            reset(){
                this.provinceValue = "";
                this.provinceOptions = [];
                this.cityValue = "";
                this.cityOptions = [];
                this.agentValue = "";
                this.agentOptions = [];
                this.hospitalValue = "";
                this.hospitalOptions = [];
                this.times = '';
                this.startDate = '';
                this.endDate = '';
            },
            onTimeChange(val){
                this.startDate = this.$dateFormat("YYYY-mm-dd",val[0]);
                this.endDate = this.$dateFormat("YYYY-mm-dd",val[1]);
            },

        }
    }

    function getItemInArray(key, arr, level) {
        if (!arr){
            return null;
        }
        for (let index in arr){
            let item = arr[index];
            if (level === 1){
                if (key.toString() === item.category1code){
                    return item;
                }
            }else if (level === 2){
                if (key.toString() === item.category2code){
                    return item;
                }
            }else if (level === 3){
                if (key.toString() === item.category3code){
                    return item;
                }
            }else if (level === 4){
                if (key.toString() === item.category4code){
                    return item;
                }
            }

        }
        return null;
    }


</script>

<style scoped>
    .toolbar{
        padding: 20px;
    }
    .el-select{
        margin-bottom: 10px;
    }
</style>